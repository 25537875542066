/*doc
---
title: SC Login Component
name: sc_login_component
category: components
---

Components used for user entry - whether it be login, account creation, or guest sign in

```html_example
<div class="sc-login-component">
	<h3>Check out as a guest</h3>

	<p>No SoulCycle account? No problem! Proceed straight to checkout.</p>

    <a class="expand-guest-checkout-form expand-button yellow-button">Continue as Guest</a>
</div>
```

```html_example
<div class="sc-login-component">
	<h3>Login</h3>

    <form method="POST">
        <input type="email" id="form_login_email" name="email" class="medium" placeholder="Email">

        <input type="password" id="form_login_password" name="password" class="medium" placeholder="Log In">

        <input class="submit yellow-button" name="submit" type="submit" value="Sign In" data-qa-id="login-submit">
    </form>
</div>
```

*/

.sc-login-component {
	.box-sizing(border-box);
	font-size: 1rem;

	form {
		display: inline-block;
		width: 100%;

		input[type=tel],
		input[type=password],
		input[type=email],
		input[type=text] {
			display: block;
			margin-left: auto;
			margin-right: auto;
		    max-width: 23.5rem;
			width: 100%;
		}

		.form-error {
			clear: both;
			float: none;
			line-height: 1.6em;
			margin-top: 1.3em;
			padding-left: 0;

			.error {
				display: block;
				margin-bottom: 0.6em;
			}
		}

		.field-row {
			display: inline-block;
			position: relative;

			.frm-label {
				.transition(all, 0.1s, linear);
				font-size: 14px;
				left: 1.2em;
				position: absolute;
				top: 1.5em;
				z-index: 11;
			}

			.intl-tel-input {
				&+ .frm-label {
					z-index: -1;

					&.show {
						left: 0;
						top: -1em;
					}
				}
			}

            .frm-label-extra {
                font-size: 0.8em;
                line-height: 1;
                margin-top: -10px;
                margin-bottom: 10px;
            }

			input {
				margin-bottom: 20px;

				&:focus {
					&+ .frm-label {
						left: 0;
						top: -1em;
					}
				}

				&+ .frm-label {
					&.show {
						left: 0;
						top: -1em;
					}
				}
			}
		}
	}

	h3 {
		.font-family(light);
		font-size: 1.4em;
		margin-bottom: 1.3em;
	}

	.ghost-button,
	.yellow-button {
		display: block;
		clear: both;
		margin: 0 auto;
		.default-browser-focus;
	}

	input {
		.default-browser-focus;
	}

	.reset-password-link {
		clear: both;
		display: block;
		font-size: 12px;
		margin-top: 1.25em;
		text-align: center;
	}

	.sc-login-component-info {
		margin-bottom: 1.3rem;
	}

	.form-expand {
		form {
			display: none;
		}

		&.show-form {
			form {
				display: inline-block;
			}

			.form-expand-button {
				display: none;
			}
		}
	}
}
